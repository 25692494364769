html,
body {
  margin: 0;
  /*background-color:whitesmoke;*/
  background:linear-gradient(45deg, rgb(243, 221, 237) 0%, rgb(153, 235, 235) 100%);
  color:steelblue;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	font-family: sans-serif;
	font-weight: 300;
	min-height:100vh;
  /* width:100%;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table {
	border-collapse: collapse;
	overflow: auto;
	box-shadow: 0 5px 5px rgba(0,0,0,0.3);
}

th {
	padding: 2px;
	color:white;
	background-color:slategray;
	word-wrap:break-word;
}

td {
	padding: 4px;
	margin:4px;
}

a {
	text-decoration:underline;
}


